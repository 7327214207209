import { mapState, mapMutations } from 'vuex'
import getBreadcrumbs from '@/utils/get-breadcrumbs'

export default {
  created() {
    this.setBreadcrumbs(getBreadcrumbs(this.$route.name))
  },

  computed: {
    ...mapState(['breadcrumbs'])
  },

  methods: {
    ...mapMutations(['setBreadcrumbs'])
  }
}
